/*
 * Fonts
 */

@font-face {
  font-family: "Neue Machina";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/NeueMachina-Regular.otf) format("opentype");
}

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize";
@import "@ionic/angular/css/structure";
@import "@ionic/angular/css/typography";
@import "@ionic/angular/css/display";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding";
@import "@ionic/angular/css/float-elements";
@import "@ionic/angular/css/text-alignment";
@import "@ionic/angular/css/text-transformation";
@import "@ionic/angular/css/flex-utils";

/*
 * Swiper
 */

@import "swiper/scss";
@import "swiper/scss/pagination";

@import "@ionic/angular/css/ionic-swiper";

/*
 * Custom
 */

:root {
  --ion-font-family: "Space Grotesk", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 0 16px;
}

ion-modal.app-auto-height-modal {
  --height: auto;
  --width: 90vw;
  --border-radius: 12px;

  .ion-page {
    position: relative;
    display: flex;
    contain: content;
    max-height: 80vh;
    overflow: auto;
    padding: 20px;
  }
}

ion-header {
  background: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
  overflow: hidden;

  &::after {
    content: none !important;
  }
}

ion-content {
  --padding-end: 0;
  --padding-start: 0;
  --padding-bottom: 36px;
  --padding-top: 0;

  > h2 {
    font-family: "Neue Machina", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.01em;
    margin: 40px 16px 16px;
  }

  > h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
  }
}

ion-card,
ion-card-content,
.card-content-md {
  > h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.01em;
  }

  > h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
  }
}

ion-footer {
  background-color: var(--ion-background-color);
}

ion-toolbar {
  --background: transparent;
  --color: var(--ion-color-tertiary-contrast);

  padding-bottom: 25px;

  ion-back-button {
    --icon-font-size: 40px;
    --padding-start: 0;
    --padding-end: 0;
  }
}

ion-title {
  font-family: "Neue Machina", sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 26px;
  letter-spacing: -0.01em;
  padding-left: 16px;
  padding-right: 16px;

  .can-go-back & {
    font-size: 28px;
    line-height: 33px;
  }
}

ion-buttons {
  > ion-back-button {
    margin-right: -8px;
  }

  + ion-title {
    padding-left: 11px;
  }
}

ion-button {
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  text-transform: unset;
  letter-spacing: -0.01em;

  &:not(.button-clear) {
    --border-style: solid;
    --border-width: 2px;
    --border-radius: 56px;
    --box-shadow: none;
    --padding-bottom: 16px;
    --padding-end: 16px;
    --padding-start: 16px;
    --padding-top: 16px;

    height: 48px;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
  }
}

ion-card {
  border: 1px solid #ececec;
  border-radius: 12px;
  box-shadow: 0px 5px 4px rgba(221, 221, 221, 0.25);
  margin: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #000000;

  ion-item {
    --inner-border-width: 0;

    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.01em;

    ion-label {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    > :not(ion-avatar) {
      padding: 10px 0;
    }
  }

  ion-card-content {
    padding: 24px;

    + ion-item {
      > :not(ion-avatar) {
        padding: 2px 0;
      }
    }
  }
}

ion-item {
  &.item-label {
    font-weight: 700;
  }

  &.item-input {
    --background-focused-opacity: 0;
    --border-radius: 50px;
    --border-width: 0;
    --highlight-height: 0;
    --highlight-background: transparent;
    --inner-border-width: 0;
    --inner-box-shadow: none;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --padding-start: 16px;
    --padding-end: 16px;

    border: 2px solid #ececec;
    border-radius: 50px;
    margin-inline-start: var(--padding-start);
    margin-inline-end: var(--padding-end);

    &.item-textarea {
      border-radius: 16px;

      ion-textarea {
        margin-top: 0;
      }
    }

    ion-icon {
      font-size: 17px;
      margin-right: 16px;
    }

    input.native-input,
    textarea.native-textarea {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 22px !important;
      letter-spacing: -0.01em !important;
      caret-color: currentColor;
    }

    &.item-has-focus {
      border-color: var(--ion-color-primary);

      ion-icon {
        color: #000000;
      }
    }
  }

  &.item-toggle {
    align-items: flex-start;
    background: #ffffff;
    border: 1px solid #ececec;
    border-radius: 12px;
    margin: 16px;

    ion-label {
      font-weight: 700;
      margin: 16px 0;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: -0.01em;
        color: #b2b2b2;
        margin: 4px 0 0;
      }
    }

    ion-toggle {
      --background-checked: var(--ion-color-tertiary);
      --handle-width: 25px;

      height: 28px;
      padding: 16px 0 0;
    }
  }
}

ion-skeleton-text {
  --border-radius: 5px;
}

.app-user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;

  ion-avatar {
    --border-radius: 20px;

    margin: 0 0 24px;
  }

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: -0.01em;
    margin: 0 0 8px;
  }

  p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.01em;
  }

  ion-button {
    --color: var(--ion-color-tertiary-contrast);
  }
}

ion-button {
  ion-spinner {
    position: absolute;
    right: 0;
  }
}

.app-text-highlight {
  color: var(--ion-color-base, inherit) !important;
}
